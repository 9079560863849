import React from "react"

const Index = () => {
  return (
    <div className="mx-10 text-center">
      <p className="mx-auto">marialourdesechandi.com</p>
    </div>
  )
}

export default Index
